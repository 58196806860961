<template>
  <navigation index="sysStoreManage" ref="navigation">
    <div :style="{ height: ContentHeight - 100 + 'px' }">
      <div
        class="topnavigation"
        style="gap: 10px; justify-content: space-between"
      >
        <div style="display: flex; gap: 10px">
          <el-button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="AddStore()"
          >
            创建
          </el-button>

          <el-button>
            <el-checkbox
              v-model="Expired"
              label="查看过期"
              @change="GetStoreList"
            ></el-checkbox>
          </el-button>

          <el-input
            v-model="SearchText"
            style="width: 320px"
            placeholder="请输入店铺名称，税号，老板名称，邮箱，手机号进行搜索"
            @keyup.enter="GetStoreList"
          ></el-input>
          <el-button type="primary" @click="GetStoreList">
            <el-icon class="no-inherit">
              <Search />
            </el-icon>
            搜索</el-button
          >
        </div>
        <div style="display: flex; gap: 10px">
          <el-button
            @click="ToStoreDefaultDataManage"
            style="margin-right: 15px"
            >默认数据管理</el-button
          >
        </div>
      </div>

      <el-table
        border
        :data="StoreDatas"
        style="width: calc(100% - 10px)"
        :height="TableHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="商户名称" prop="Name" width="150">
        </el-table-column>
        <el-table-column label="审核" prop="Approve" width="100">
          <template #default="scope">
            <el-tag v-if="scope.row.Approve"> 已通过 </el-tag>
            <el-button
              type="success"
              @click="ApproveStore(scope.row)"
              v-if="!scope.row.Approve"
            >
              允许使用
            </el-button>
          </template>
        </el-table-column>

        <el-table-column label="状态" prop="Forbidden" width="100">
          <template #default="scope">
            <el-tag type="success" v-if="!scope.row.Forbidden"> 正常 </el-tag>
            <el-tag type="info" v-if="scope.row.Forbidden"> 已禁用 </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="归属公司" prop="CompanyName" width="180">
        </el-table-column>
        <el-table-column label="到期时间" prop="ExpireDateFormat" width="180">
        </el-table-column>
        <el-table-column label="商户号" prop="DomainName" width="180">
        </el-table-column>
        <el-table-column label="介绍内容" prop="Content" width="180">
          <template #default="{ row }">
            <div v-html="row.Content"></div>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" prop="Mailbox" width="220">
        </el-table-column>
        <el-table-column label="操作" width="500" fixed="right">
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="StoreEdit(scope.$index, scope.row)"
              >编辑</el-button
            >

            <el-button
              size="small"
              type="warning"
              @click="OpenAuthorizationWindow(scope.$index, scope.row)"
              >授权管理</el-button
            >

            <el-button
              size="small"
              type="primary"
              @click="OpenStorRelevanceUser(scope.$index, scope.row)"
              >账号管理</el-button
            >

            <el-button
              size="small"
              type="success"
              @click="InsertStoreDefault(scope.row)"
              >更新默认数据</el-button
            >

            <!-- <el-button
              size="small"
              type="primary"
              @click="OpenTransferDataAnalyse(scope.$index, scope.row)"
              >迁移</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>

      <div style="height: 50px; display: flex; align-items: center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentChange"
          :page-size="padding.Size"
          :total="padding.Count"
        >
        </el-pagination>
      </div>

      <!-- 修改新增店铺 -->
      <el-dialog
        v-model="EditOrAddWindow"
        :title="IsAdd ? '新增' : '修改'"
        width="60%"
        :before-close="EditOrAddWindowClose"
        :show-close="false"
      >
        <div style="padding: 20px 5px">
          <el-form
            label-width="120px"
            ref="StoreForm"
            :model="StoreModel"
            :rules="StoreRules"
          >
            <el-form-item label="所属公司">
              <div style="font-size: 17pt">
                {{ StoreModel.CompanyName }}
              </div>
              <el-button
                @click="OpenCompanyCheckWindow()"
                style="margin-left: 30px"
                type="primary"
                size="small"
                >选择公司</el-button
              >
            </el-form-item>
            <el-form-item label="商户名称" prop="Name">
              <el-input v-model="StoreModel.Name"></el-input>
            </el-form-item>

            <el-form-item label="商户号">
              <el-input v-model="StoreModel.DomainName"></el-input>
            </el-form-item>

            <el-form-item label="城市" prop="City">
              <el-input v-model="StoreModel.City"></el-input>
            </el-form-item>

            <el-form-item label="地址" prop="Location">
              <el-input v-model="StoreModel.Location"></el-input>
            </el-form-item>
            <el-form-item label="老板姓名" prop="ShopBossName">
              <el-input v-model="StoreModel.ShopBossName"></el-input>
            </el-form-item>
            <el-form-item label="税号" prop="TaxNumber">
              <el-input v-model="StoreModel.TaxNumber"></el-input>
            </el-form-item>
            <el-form-item label="邮编" prop="ZipCode">
              <el-input v-model="StoreModel.ZipCode"></el-input>
            </el-form-item>

            <el-form-item label="手机号" prop="PhoneNumber">
              <el-input v-model="StoreModel.PhoneNumber"></el-input>
            </el-form-item>

            <el-form-item label="邮箱" prop="Mailbox">
              <el-input v-model="StoreModel.Mailbox"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model="StoreModel.Password"
                placeholder="默认为admin"
              ></el-input>
            </el-form-item>

            <el-form-item label="账号最大数量">
              <el-input-number
                v-model="StoreModel.AccountMaxCount"
                placeholder="默认为50个"
              ></el-input-number>
            </el-form-item>

            <el-form-item label="备注">
              <el-input v-model="StoreModel.Content"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                v-if="!StoreModel.Forbidden"
                @click="StoreForbidden(0, StoreModel)"
                type="info"
                >禁用商户</el-button
              >
              <el-button
                type="danger"
                v-if="StoreModel.Forbidden"
                @click="RemoveForbidden(0, StoreModel)"
                >取消禁用</el-button
              >

              <el-button type="warning" @click="DeleteStore(StoreModel)"
                >删除</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="EditOrAddWindowClose">关闭</el-button>
            <el-button type="primary" @click="EditOrAddSave('StoreForm')"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 授权页面 -->
      <el-dialog
        v-model="AuthorizationWindow"
        title="授权管理"
        width="60%"
        :show-close="false"
      >
        <el-tabs
          v-model="AuthorizationWindowActiveName"
          class="demo-tabs"
          @tab-click="GetAuthorizationLog"
        >
          <el-tab-pane label="操作" name="first">
            <el-scrollbar height="400px">
              <el-form label-width="120px">
                <el-form-item label="商户名称">
                  <el-input
                    v-model="AuthorizationModel.Name"
                    disabled="false"
                  ></el-input>
                </el-form-item>
                <el-form-item label="到期时间">
                  <el-date-picker
                    v-model="AuthorizationModel.ExpireDate"     value-format="YYYY/MM/DD HH:mm:ss"
                    type="date"
                    placeholder="Pick a day"
                    :size="size"
                  />
                </el-form-item>
                <el-form-item label="收费金额">
                  <el-input-number
                    @focus="
                      (event) => {
                        event.currentTarget.select();
                      }
                    "
                    ref="AuthorizationAmount"
                    v-model="AuthorizationModel.Amount"
                    :precision="2"
                    :step="0"
                  />
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="AuthorizationModel.Remark"></el-input>
                </el-form-item>
              </el-form>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="历史" name="second">
            <el-table
              border
              :data="AuthorizationList"
              style="width: 100%"
              :height="'400px'"
            >
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column
                label="到期时间"
                prop="ExpireDateFormat"
                width="220"
              >
              </el-table-column>
              <el-table-column label="缴费金额" prop="Amount" width="220">
              </el-table-column>
              <el-table-column label="备注" prop="Remark" width="220">
              </el-table-column>
            </el-table>

            <el-pagination
              style="padding: 5px"
              background
              layout="prev, pager, next"
              @current-change="CurrentChangeAuthorization"
              :page-size="PaddingAuthorizationLog.Size"
              :total="PaddingAuthorizationLog.Count"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="AuthorizationWindow = false">关闭</el-button>
            <el-button type="primary" @click="AuthorizationSave()"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 店铺账号 -->
      <el-dialog
        v-model="StorRelevanceUserWindow"
        title="店铺账号"
        width="60%"
        :show-close="false"
      >
        <el-table
          border
          :data="StorRelevanceUsers"
          style="width: 100%"
          :height="height - 270"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column label="用户名称" prop="UserName" width="180">
          </el-table-column>
          <el-table-column label="用户账号" prop="UserAccount" width="180">
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="CancelRelevanceStore(scope.$index, scope.row)"
                >解除关联</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="StorRelevanceUserWindow = false">关闭</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- 公司选择框 -->
      <el-dialog
        v-model="CompanyCheckWindow"
        title="公司选择"
        :show-close="false"
        width="60%"
      >
        <div style="margin: 5px; display: flex">
          <el-input
            placeholder="筛选"
            v-model="CompanyNameWhere"
            style="width: 200px"
          >
          </el-input>
          <el-button
            @click="GetCompanyList"
            style="margin-left: 10px"
            type="primary"
            >查询</el-button
          >
        </div>
        <el-table
          border
          :data="CompanysDatas"
          style="width: 100%"
          :height="300"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column label="公司名称" prop="Name" width="220">
          </el-table-column>

          <el-table-column label="邮箱" prop="Mailbox" width="220">
          </el-table-column>
          <el-table-column label="手机号" prop="Phone" width="220">
          </el-table-column>
          <el-table-column label="备注" prop="Content" width="180">
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="CheckCompanys(scope.$index, scope.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="CompanyCheckWindow = false">关闭</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog
        v-model="TransferDataAnalyseWindow"
        title="请选择需要迁移的数据"
        width="60%"
        :show-close="false"
      >
        <div
          class="el-upload__tip"
          style="
            overflow: auto;
            height: 30px;
            display: flex;
            gap: 15pt;
            font-size: 16pt;
          "
          v-if="TransferData != null"
        >
          <div>
            <div>维修单:{{ TransferData.repairs.length }}</div>
          </div>
          <div>
            <div>客户: {{ TransferData.clients.length }}</div>
          </div>
          <div>
            <div>维修问题/服务:{{ TransferData.services.length }}</div>
          </div>
          <div>
            <div>配件: {{ TransferData.modules.length }}</div>
          </div>
        </div>
        <el-upload
          style="margin: 30px"
          class="upload-demo"
          drag
          :action="UploadPictureImageUrl"
          :show-file-list="false"
          :on-success="UploadSuccess"
          :before-upload="UploadSuccess"
          :headers="UploadHeaders"
          multiple
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">请选择上传的文件</div>
          <template #tip> </template>
        </el-upload>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="TransferDataAnalyseWindow = false"
              >关闭</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </navigation>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import { ElMessageBox, ElMessage, UploadFilled } from "element-plus";
import "../../../assets/css/commons.css";
import { Search, Close, Refresh, Message } from "@element-plus/icons-vue";

import navigation from "../../../components/Modules/AdminNavigation.vue";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      AuthorizationWindowActiveName: "first",

      ContentHeight: document.documentElement.clientHeight - 200,
      TableHeight: document.documentElement.clientHeight - 120,

      StoreDatas: [],
      StoreModel: {
        AccountMaxCount: 50,
      }, // 选中用户
      EditOrAddWindow: false, // 修改或者新增窗口
      IsAdd: false, // 是否是新增
      StoreInvoiceWindow: false, //发票信息窗口
      StoreInvoiceOpt: {
        SignatureSoftware: {},
      },
      ProvinceList: [],

      RoleBindWindow: false,
      RoleDatas: [],
      SearchText: "", //筛选信息
      NewPassword: "",

      AuthorizationWindow: false,
      AuthorizationModel: {}, //授权账号信息
      AuthorizationList: [],
      Expired: false, //筛选过期

      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      StorRelevanceUsers: [],
      StorRelevanceUserWindow: false,
      FileList: [],
      UploadHeaders: {},

      ApiUrl: "",
      CompanyId: "",
      CompanyOpt: {
        Name: "未选择",
        Id: "",
      }, //选中的公司
      CompanysDatas: [], //公司列表
      CompanyCheckWindow: false,
      CompanyNameWhere: "",
      CompanyPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      TransferData: null,
      TransferDataAnalyseWindow: false,
      StoreRules: {
        Name: [
          { required: true, message: "请输入商店名称", trigger: "blur" },
          { min: 3, max: 35, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        Mailbox: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            min: 4,
            max: 35,
            message: "长度在 3 到 35 个字符",
            trigger: "blur",
          },
        ],
      },

      PaddingAuthorizationLog: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
    };
  },

  //repairs[2373].ticket.items[0].price

  name: "App",
  mounted() {
    this.CompanyId = this.$route.query.companyId;
    this.GetCompanysById(this.CompanyId);
    this.GetStoreList();
    this.GetCompanyList();

    this.ApiUrl = axios.GetUrl();
    this.UploadHeaders = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    this.UploadPictureImageUrl =
      axios.GetUrl() + "/admins/StoreMaintain/TransferDataAnalyse";

    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
  },
  components: { navigation, Search },
  methods: {
    InsertStoreDefault(item) {
 
      axios.apiMethod(
        "/admins/StoreMaintain/InsertStoreDefault",
        "get",
        {
          storeId: item.Id,
        },
        (result) => {

          ElMessageBox({
              title: "提示",
              message: '已完成更新',
              type: "success",
            });

          if (result.Data.IsSuccess) {
            this.GetStoreList();
          } else {
           
          }
        }
      );

      

    }, 
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        if (response.Data.IsSuccess) {
          this.TransferData = response.Data.Data;
        }
      }
    },
    //迁移
    OpenTransferDataAnalyse() {
      this.TransferDataAnalyseWindow = true;
    },

    ToStoreDefaultDataManage() {
      this.$router.push({
        path: "storeDefaultDataManage",
      });
      return;
    },
    //删除商户
    DeleteStore(item) {
      ElMessageBox.alert(
        `您确定要将 <span style='color:red;font-size:16pt'>${item.Name}</span> 商户删除吗？ `,
        "请确认！",
        {
          cancelButtonText: "Cancel",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          callback: (action) => {
            axios.apiMethod(
              "/admins/StoreMaintain/DeleteStoreById",
              "get",
              {
                storeId: item.Id,
              },
              (result) => {
                if (result.Data.IsSuccess) {
                  this.GetStoreList();
                  this.EditOrAddWindow = false;
                } else {
                  ElMessageBox({
                    title: "提示",
                    message: result.Data.Msg,
                    type: "error",
                  });
                }
              }
            );
          },
        }
      );
    },
    //同意使用
    ApproveStore(item) {
      axios.apiMethod(
        "/admins/StoreMaintain/ApproveStore",
        "get",
        {
          id: item.Id,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetStoreList();
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    OpenCompanyCheckWindow() {
      this.CompanyCheckWindow = true;
    },
    //选择公司
    CheckCompanys(index, item) {
      ElMessageBox.alert(
        `您确定要将 <span style='color:red;font-size:16pt'>${this.StoreModel.Name}</span> 商户的公司变更为 <br /> 
         <span style='color:red;font-size:16pt'>${item.Name}</span>  吗？ `,
        "请确认！",
        {
          cancelButtonText: "Cancel",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          callback: (action) => {
            this.StoreModel.CompanyId = item.Id;
            this.StoreModel.CompanyName = item.Name;
            this.CompanyCheckWindow = false;
          },
        }
      );
    },

    // 获取公司列表
    GetCompanyList() {
      this.CompanyPadding.Where = {
        Name: this.CompanyNameWhere,
        Account: "",
      };

      axios.apiMethod(
        "/admins/Company/GetCompanyList",
        "post",
        this.CompanyPadding,
        (result) => {
          if (result.Code == 200) {
            this.CompanysDatas = result.Data.Datas;
            this.CompanyPadding.Page = result.Data.Page;
            this.CompanyPadding.Size = result.Data.Size;
            this.CompanyPadding.Count = result.Data.Count;
          }
        }
      );
    },

    //刷新
    CurrentChangeAuthorization(index) {
      this.PaddingAuthorizationLog.Page = index;
      this.GetStoreList();
    },
    //获取授权日志
    GetAuthorizationLog() {
      this.PaddingAuthorizationLog.Where = {
        Id: this.AuthorizationModel.Id,
      };

      axios.apiMethod(
        "/admins/StoreMaintain/GetAuthorizationLog",
        "post",
        this.PaddingAuthorizationLog,
        (result) => {
          if (result.Code == 200) {
            this.AuthorizationList = result.Data.Datas;
            this.PaddingAuthorizationLog.Page = result.Data.Page;
            this.PaddingAuthorizationLog.Size = result.Data.Size;
            this.PaddingAuthorizationLog.Count = result.Data.Count;
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //获取公司信息
    GetCompanysById(companyId) {
      axios.apiMethod(
        "/admins/Company/GetCompanysById",
        "get",
        {
          compaysId: companyId,
        },
        (result) => {
          if (result.Data != null) {
            this.CompanyOpt = result.Data;
          }
        }
      );
    },
    //取消关联
    CancelRelevanceStore(index, item) {
      axios.apiMethod(
        "/admins/StoreUser/CancelRelevanceStore",
        "get",
        {
          Id: item.Id,
        },
        (result) => {
          if (result.Data.Success) {
            ElMessage({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: "操作成功",
            });
            this.StorRelevanceUserWindow = false;
            this.GetStoreList();
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              message: result.Data.Msg,
            });
          }
        }
      );
    },
    //打开商店用户页面
    OpenStorRelevanceUser(index, item) {
      this.$router.push({
        path: "accountManage",
        query: {
          storeId: item.Id,
        },
      });

      return;
    },
    //保存授权信息
    AuthorizationSave() {
      ElMessageBox.alert(
        `您正在为商户${this.AuthorizationModel.Name} 手动续期，截至时间为${this.AuthorizationModel.ExpireDate}  `,
        "Title",
        {
          confirmButtonText: "OK",
          callback: (action) => {
            axios.apiMethod(
              "/admins/StoreMaintain/RenewalFee",
              "post",
              this.AuthorizationModel,
              (result) => {
                if (result.Data.IsSuccess) {
                  this.AuthorizationWindow = false;
                  this.GetStoreList();
                } else {
                  ElMessageBox({
                    title: "提示",
                    message: result.Data.Msg,
                    type: "error",
                  });
                }
              }
            );
          },
        }
      );
    },
    //授权页面
    OpenAuthorizationWindow(index, item) {
      this.AuthorizationModel = item;
      this.AuthorizationModel.Amount = 0.0;
      this.AuthorizationWindow = !this.AuthorizationWindow;
    },
    //编辑页面
    EditOrAddWindowClose() {
      this.EditOrAddWindow = false;
    },
    // 保存
    EditOrAddSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const param = this.StoreModel;

          axios.apiMethod(
            "/admins/StoreMaintain/InsterStore",
            "post",
            param,
            (result) => {
              if (result.Data.IsSuccess) {
                this.EditOrAddWindow = false;
                this.GetStoreList();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: result.Data.Msg,
                  type: "error",
                });
              }
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      return;
    },
    // 创建商户
    AddStore() {
      this.StoreModel = {
        Email: "",
        Name: "",
        Phone: "",
        Password: "",
        Roles: [],
        CompanyName: this.CompanyOpt.Name,
        CompanyId: this.CompanyOpt.Id,
        AccountMaxCount: 50,
      };
      this.IsAdd = true;
      this.EditOrAddWindow = true;
    },
    // 编辑
    StoreEdit(index, row) {
      this.EditOrAddWindow = true;
      this.StoreModel = row;
      this.IsAdd = false;
      this.GetCompanysById();
    },

    // 禁用
    StoreForbidden(index, row) {
      ElMessageBox.confirm("确定要禁用吗？")
        .then(() => {
          axios.apiMethod(
            "/admins/StoreMaintain/Forbidden?Id=" + row.Id,
            "get",
            {},
            (result) => {
              this.EditOrAddWindow = false;
              ElMessageBox({
                title: "",
                message: "操作成功",
                type: "success",
              });
              this.GetStoreList();
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    // 取消禁用
    RemoveForbidden(index, row) {
      ElMessageBox.confirm("确定要取消禁用吗？")
        .then(() => {
          axios.apiMethod(
            "/admins/StoreMaintain/RemoveForbidden?Id=" + row.Id,
            "get",
            {},
            (result) => {
              this.EditOrAddWindow = false;
              ElMessageBox({
                title: "",
                message: "操作成功",
                type: "success",
              });
              this.GetStoreList();
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //刷新
    CurrentChange(index) {
      this.padding.Page = index;
      this.GetStoreList();
    },
    // 获取商户列表
    GetStoreList() {
      this.padding.Where = {
        CompanyId: this.CompanyId,
        Account: "",
        Expired: this.Expired,
      };
      this.padding.Search = this.SearchText;
      axios.apiMethod(
        "/admins/StoreMaintain/GetStoreList",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.StoreDatas = result.Data.Datas;
            this.padding.Page = result.Data.Page;
            this.padding.Size = result.Data.Size;
            this.padding.Count = result.Data.Count;
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
